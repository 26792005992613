import React, { useEffect, useState } from 'react'
import './Countdown.css'
function Countdown ({ date }) {
  const calculateTimeLeft = () => {
    const difference = new Date(date) - Date.now()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => clearTimeout(timer)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    let temp = timeLeft[interval]

    if (temp < 10) {
      temp = '0' + temp
    }
    timerComponents.push(temp || '00')
  })

  return (
    <div className='timer'>
      <div className='timer_column'>
        <h1>{timerComponents[0]}</h1>
        <p>Days</p>
      </div>
      <p>:</p>
      <div className='timer_column'>
        <h1>{timerComponents[1]}</h1>
        <p>Hrs</p>
      </div>
      <p>:</p>
      <div className='timer_column'>
        <h1>{timerComponents[2]}</h1>
        <p>Mins</p>
      </div>
      <p>:</p>
      <div className='timer_column'>
        <h1>{timerComponents[3]}</h1>
        <p>Secs</p>
      </div>
    </div>
  )
}

export default Countdown
