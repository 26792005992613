import React from 'react'
import "./Sponsors.css"
function DownArrow() {
  return (
    <div className="arrow-container">
        <div className="arrow">            
        </div>
        <div className='arrow'></div>
    </div>
  )
}

export default DownArrow