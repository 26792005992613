import React from 'react'
import "./Sponsors.css"
import DownArrow from "./DownArrow"
import sponsors from "./sponsors.json"
function Sponsors({ref}) {
  return (
    <section id='sponsors' ref={ref}>
      <h1 className='sponsors-header'>Sponsors</h1>
      <div className="sponsors-logo">
      {sponsors.map((sponsor)=>
      <div className='sponsor-tag'>
        
        <div className='logo-image' style={sponsor.style}>
        <a href={sponsor.link} target='_blank' rel='noreferrer' ><img   src={sponsor.img} alt={sponsor.name} />
        </a>
      </div>
      <h2 className='sponsor-title'>{sponsor.title}</h2>
      </div>)}
      </div>
    </section>
  )
}

export default Sponsors