import React from 'react'
import Typewriter from 'typewriter-effect'
function TypewriterText ({
  strings,
  cursor = true,
  pauseTime = 2000,
  delay = 100,
  deleteSpeed = 'natural',
  loop = true
}) {
  return (
    <Typewriter
      options={{
        autoStart: true,
        loop,
        delay,
        cursor: cursor ? '|' : ''
      }}
      onInit={(typewriter) => {
        strings.forEach((ele, i) => {
          if (i > 0) {
            typewriter.deleteAll()
          }
          typewriter.typeString(ele).pauseFor(pauseTime)
        })
        typewriter.start()
      }}
    />
  )
}

export default TypewriterText
