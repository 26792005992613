import React from 'react'
import "./Hamburger.css"
function Hamburger({onClick, isOpen}) {
  return (
    <button className={'hamburger-icon '+(isOpen?"open":"")} onClick={onClick}>
        <div className='line'></div>
        <div className='line'></div>
        <div className='line'></div>
    </button>
  )
}

export default Hamburger