import React from 'react'
import './Social.css'
import twitter from "./Assets/twitter.svg"
import instagram from "./Assets/instagram.svg"
import threads from "./Assets/threads.png"
import discord from "./Assets/discord.png"
const icons = {twitter,instagram, threads, discord}
function Social({icon, link}) {


  return (
    <a className='social-icon' href={link}>
        <img src={icons[icon]} alt="" />
    </a>
  )
}

export default Social