import './App.css'
import {useCallback, useRef, useState} from 'react';
import { ReactComponent as Gdsc } from './Assets/GDSC.svg'
import { ReactComponent as Ctf } from './Assets/logo.svg'
import Countdown from './Countdown'
import Sponsors from './Sponsors'
import TypewriterText from './TypewriterText'
import DownArrow from "./DownArrow"
import Button from './Button';
import Social from './Social';
import Hamburger from './Hamburger';
import socials from "./socials.json"
function App () {

  const [socialsOpen, setSocialsOpen] = useState(false)
  const ref = useRef(null)

  return (
    <>
      <div className='bg-holder' />
      <div className='nav-holder'>
        <Gdsc className='gdsc-logo' />
      </div>
      <Hamburger onClick={()=>setSocialsOpen(!socialsOpen)} isOpen={socialsOpen}/>
      <div className='right_top_corner'>
        <p>
          <span> {'>'} </span>
          <TypewriterText loop={false} strings={['Greetings']} />
        </p>
        <p> {'> -'} </p>
        <p>
          <span> {'>'} </span>
          <TypewriterText
            strings={['INITIALIZING SEQUENCE', 'INITIALIZATION DONE!']}
          />
        </p>
      </div>
      <div className={'socials '+(socialsOpen?"open":"")}>
        {socials.map((social)=><Social icon={social.name} link={social.link}/>)}
      </div>
      <div className='container'>
        <div className='ctf-holder'>
          <Ctf className='logo' />
        </div>
        <div>
          <p className='date'>5th - 6th Aug 2023</p>
        </div>
        <div className='terminal'>
          <div className='t-bar'>
            <div className='t-buttons'>
              <div className='red' />
              <div className='yellow' />
              <div className='green' />
            </div>
            <h1 className='t-header'>DeconstruCT.F 2023</h1>
            <div />
          </div>
          <div className='t-body'>
            <p className='t-text'>
              A{' '}
              <span style={{ color: '#61DC83', fontWeight: '700' }}>
                36 hour
              </span>{' '}
              jeopardy style{' '}
              <span style={{ color: '#FF5F56', fontWeight: '700' }}>CTF</span>,
              ranging to{' '}
              <span style={{ color: '#61DC83', fontWeight: '700' }}>
                multiple different domains
              </span>{' '}
              , namely, web, forensics, cryptography, OSINT etc!
              <br />
            </p>
          </div>
        </div>
        {/* <p className='coming_soon'>Coming Soon...</p> */}
        <Button>Register</Button>
        <div className='countdown_container'>

          <Countdown date='2023-08-05 13:37:00' />

        </div>
        <div className='sponsors-arrow' onClick={()=>{
          document.getElementById("sponsors").scrollIntoView({behavior:'smooth'})
        }}>
          <h1>Know Your Sponsors</h1>
          <DownArrow/>
        </div>
        
      </div>
      <Sponsors ref={ref}/>
      
    </>
  )
}

export default App
